/* In App.css or another CSS file */
body {
  font-family:  sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.7em;
}

:root {
  --display: flex;
  --flex-direction: row;
  --container-widget-width: initial;
  --container-widget-height: 100%;
  --container-widget-flex-grow: 1;
  --container-widget-align-self: stretch;
  --flex-wrap-mobile: wrap;
  --gap: 0px 0px;
  --flex-wrap: wrap;
  --background-transition: 0.3s;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-left: 0px;
  --padding-right: 0px;
}

.menu {
  display: block;
}
.bg-dark-blue {
  background: #0a1d56;

}
.hero-inner {
  flex-wrap: wrap;
  justify-content: initial;
  align-items: initial;
  align-content: initial;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: auto;
  background: none;
  height: 114px!important;
  color: white;
  gap: 0px 0px;
  width: 100%;
  max-width: min(100%, 1140px) !important;
  margin: 0 auto;
  padding-inline-start: 0;
  padding-inline-end: 0;
  height: 100%;
}
.nav-inner {
  flex-wrap: wrap;
  justify-content: initial;
  align-items: initial;
  align-content: initial;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: auto;
  background: #0a1d56;
  height: 114px!important;
  color: white;
  gap: 0px 0px;
  width: 100%;
  max-width: min(100%, 1140px) !important;
  margin: 0 auto;
  padding-inline-start: 0;
  padding-inline-end: 0;
  height: 100%;
}
.content-inner {
  flex-wrap: wrap;
  justify-content: initial;
  align-items: initial;
  align-content: initial;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: auto;
  color: black;
  gap: 0px 0px;
  width: 100%;
  max-width: min(100%, 1140px) !important;
  margin: 0 auto;
  padding-inline-start: 0;
  padding-inline-end: 0;
  height: 100%;
}

.nav-link {
  color: white !important;
  padding: 40px 20px 40px 15px !important;
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: auto;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  justify-content: flex-end;

}

.navbar-nav li,
.navbar-nav ul {
  list-style: none !important;
  margin: 0;
  padding: 0;
}
.custom-dropdown .dropdown-toggle::after {
  display: none; /* Hide default dropdown icon */
}

.custom-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
}

.custom-dropdown .dropdown-toggle .fa-chevron-down,
.custom-dropdown .dropdown-toggle .fa-chevron-up {
  margin-left: 0.1rem;
  margin-bottom: 3px;
    display: inline;
}
/* .btn-login {
  padding: 15px 30px 15px 30px !important;
  background-color: #2fd6ff !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  font-size: 0.875rem !important;
  border-radius: 30px !important;
  margin: 0 20px !important;
} */
.menu {
  display: block;
}
.navbar {
  
  height: 114px!important;

}
.nav-link {
  color: white;
}
.nav-link.active {
  color: #2fd6ff!important;
}
.btn-login {
  display: inline!important;
  background-color: #c6c6c6;
  color: white!important;
  line-height: 1;
  font-size: 14px!important;
  padding: 15px 30px 15px 30px!important;
  border-radius: 30px!important;
  color: #fff!important;
  fill: #fff!important;
  background-color: #2fd6ff!important;
  text-align: center;
  transition: all .3s!important;

}
.btn-login:hover{
  background-color: #25add0!important;
  border: 1px solid!important;
  border-color: white!important;
  
}
#basic-navbar-nav{
  padding-left: 15%;
}
.dropdown-menu {
  background: #0A1D56E6!important;
  border: none!important;
  border-radius: 0%;
  opacity: 1!important;
  top: 74% !important;
  padding: 0 !important;
  color: white;
}
.dropdown-item {
  background: #0A1D56E6!important;
  border: none!important;
  opacity: 1!important;
  PADDING: 10px 26px 10px 57px!important;
  text-align: left!important;
  color: white!important;
  border-bottom: 2px solid white!important;
  font-size: 16px;
  border-radius: 0%!important;
}
.dropdown-item:hover {
  color: #2fd6ff!important;
  font-size: 16px;
  border-radius: 0%;
  background: none!important;
  background-color: #0A1D56E6!important;

}
.dropdown-item:last-child {
  background: #0A1D56E6;
  border: none;
  opacity: 1;
  padding: 10px 26px 10px 57px;
  text-align: left;
  border-bottom: none!important;
  font-size: 16px;
  border-radius: 0%;
}