/* Footer.css */
.footer {
    background-color: #0a1d56;
    padding: 3rem 0 1rem;
    text-align: start;
    width: 100%;
    margin: 0 auto;
    padding-inline-start: 0;
    padding-inline-end: 0;
    height: 100%;
  }
  .icon-with-stroke {
    stroke: #2fd6ff;
    stroke-width: 100px;
    margin-bottom: 2px;
    stroke-opacity: 100%;
  }
  .footer a {
    color: white;
    margin: auto 0;
    text-decoration: none!important;
  }
  
  .footer a:hover {
    text-decoration: none!important;
    color: rgb(47, 214, 255);

  }
  
  .footer ul {
    text-align: start;

    column-count: 2;
    column-gap: 20px; /* Adjust the gap between columns as needed */
    list-style-type: none; /* Optional: removes default bullets */
    padding: 0; /* Optional: removes default padding */
  }

  .footer ul li {
    break-inside: avoid; /* Prevents breaking items in the middle */
    margin-bottom: 10px; /* Adds some space between items */  
    text-align: start;}

  
  .footer p {
    font-size: 0.9rem;
    color: #ffffff;
    text-align: start;

    
  }
  .footer-text {
    display: block;
    margin: auto 0;
  }
  .footer .img-eu {
        max-width: 100%;
        height: 43.85px;
        display: flex;
        text-align: start;
        justify-content: start;
    
  }
  .footer .footer-inner {
      flex-wrap: wrap;
      justify-content: initial;
      align-items: initial;
      align-content: initial;
      flex-basis: auto;
      flex-grow: 1;
      flex-shrink: 1;
      align-self: auto;
      gap: 0px 0px;
      width: 100%;
      max-width: min(100%, 1140px) !important;
      margin: 0 auto;
      padding-inline-start: 0;
      padding-inline-end: 0;
      height: 100%;
    
    
  }