.copyright {
    background-color: #3e3e3e;
    color: white;
    
}
.container{
    gap: 0px 0px;
    width: 100%;
    max-width: min(100%, 1140px) !important;
}
p {margin-top: 1rem;}
a {color: white;
text-decoration: none;}