/* Hero.css */
.hero {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    height: 400px;
    background-size: cover;
    background-position: center;
    color: white;
 
  background-image: url("https://subra.ics.forth.gr/wp-content/uploads/2024/07/Header-Static-BG.jpg");
    background-position: center center;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .hero-title {
    font-family:  sans-serif;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    text-align: left;
    line-height: 77px;
    margin-bottom:0;
  }
  
  .hero-subtitle {
    color: rgb(47, 214, 255);
    font-family:  sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    text-align: left;
  }
  